.dialogContent h2, .dialogContent h3, .dialogContent h4, .dialogContent h5, .dialogContent h6 {
    color: #243F8F; 
  }
  
  .dialogContent h2 {
      font-size: 1.3rem;
      color: #243F8F; 
      font-weight: 700;
    }
  
  .dialogContent p, .dialogContent ul, .dialogContent a {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #677292;
  }
  
  .dialogContent a {
    color: #5FB2FF;
    text-decoration: underline;
  }
  
  
  @media (min-width: 600px) {
    .dialogContent h2 {
      font-size: 1.5rem;
    }
    .dialogContent p {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
  