.htmlContent a, .htmlContent ol, .htmlContent dl {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #677292;
  }

.htmlContent p, .htmlContent li, .htmlContent dl, .htmlContent dt, .htmlContent dd {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #677292;
    padding-bottom: 10px; 
  }
  
  .htmlContent h2, .htmlContent h3 {
    color: #243F8F;
    font-weight: 700;
  }
  
  .htmlContent h2 {
    font-size: 1.3rem;
  }
  
  .htmlContent h3 {
    font-size: 1.125rem;
  }
  
  @media (min-width: 600px) {
    .htmlContent {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
  }
